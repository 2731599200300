
export default (state, action) => {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        loading: true
      }

    case 'FETCH_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false
      }

    case 'SET_VALUES':
      return{
          ...state,
          ...action.payload
      }

    case 'SET_IMAGE':
      return{
          ...state,
          image: action.payload
      }

    case 'SET_IMAGE_RAW':
      return{
          ...state,
          image_raw: action.payload
      }

    case 'SET_CROP_IMAGE':
      return{
        ...state,
        image_crop: action.payload
      }

    case 'SET_SHARE_IMAGE':
      return{
          ...state,
          share_image: action.payload
      }

    case 'CHANGE_PAGE':
      return{
          ...state,
          page: action.payload
      }

    case 'CHOOSE_QUOTE':
      return{
          ...state,
          quote_id: action.payload.id,
          quote_selected: action.payload.quote
      }

    case 'FETCH_ERROR':
      return {
        ...state,
        error: 'Could not load data',
        loading: false
      }
    default:
      return state
  }
}
