import React from 'react'
import { StateProvider, initialState } from './State'
import reducers from './reducers'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {MainLoadable} from './Loadable';


export default function() {
  return (
    <StateProvider initialState={initialState} reducer={reducers}>
	    <Router>
	    	<Route path={'/'} exact component={MainLoadable}/>
	    </Router>
    </StateProvider>
  )
}
