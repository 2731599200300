// import 'core-js/stable'; // polyfill only stable `core-js` features - ES and web standards:
import "core-js"; // polyfill all `core-js` features:

import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import Routes from './Routes'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://61fa8a7b12334edf81cd96592a3b3a1f@sentry.io/1918522"});

ReactDOM.render(<Routes />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
