import React, { createContext, useContext, useReducer } from 'react'

export const StateContext = createContext()

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStateValue = () => useContext(StateContext)

export const initialState = {
  loading: true,
  error: false,
  modal: false,
  page: 'landing',
  image: null,
  image_crop: null,
  share_image: null,
  quote_id: 0,
  quote_selected:'',
  quotes_list: [
      `เจ้าดอกไม้<br/>กลิ่นชวนหอมเพียงใด<br/>/nแต่อยากจะหายใจ<br/>เก็บเธอไว้ให้มากกว่า`,
      `เธอคนนี้<br/>เธอช่างสวยงาม<br/>เกินกว่า`,
      `เธอคือลมหายใจ<br/>ที่อ่อนไหวของดวงดาว`,
      `รอยยิ้มเธอ<br/>ทำฉันต้องมนต์สะกด`,
      `แสงดาวจากฟ้า<br/>ที่เธอเก็บรวมกันไว้<br/>ในดวงตา`,
      `โอ้ใจเอ๋ย<br/>ก่อนเคยถามรัก<br/>/nเป็นอย่างไรแต่เมื่อพบเธอ<br/>จึงเข้าใจทุกอย่าง`,
      `คนที่สวยงาม<br/>อ่อนโยนและสดใส`,
      `อยากให้ฉัน<br/>ดูแลเธอจะได้ไหม<br/>ต่อจากนี้ ทุกวินาที`,
      `My love that's all I<br/>want you to see`
  ]
}
