import React from 'react';
import Loadable from 'react-loadable';

import { Loader } from './Loader';

const LoadingComponent = props => {
  if (props.error) {
    return (
      <div>
        Error! 
      </div>
    );
  } else if (props.timedOut) {
    return (
      <div>
        Taking a long time... 
      </div>
    );
  } else if (props.pastDelay) {
    return <Loader />;
  } else {
    return null;
  }
};

export const MainLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ '../pages/Main'),
  loading: LoadingComponent,
  timeout: 5000 // 5 s
});
